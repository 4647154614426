import React from "react";

import SharedDelivery from "../components/SharedDelivery";
import BaseLayout from "../layout/BaseLayout";

const SharedDeliveryPage: React.FC = () => {
  return (
    <BaseLayout>
      <SharedDelivery />
    </BaseLayout>
  );
};

export default SharedDeliveryPage;
